import { Component, OnInit, Inject, Optional, PLATFORM_ID, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { environment } from '@env';
import { LOCATION_INITIALIZED, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { HeaderService } from 'src/app/core/services/header.service';
import { FooterService } from 'src/app/core/services/footer.service';


@Component({
  selector: 'notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'notfound'
  }
})
export class NotfoundComponent implements OnInit {
  env = environment;
  isRecruiters: boolean;

  routerSub: Subscription = new Subscription();
  logoColor = this.env.path.img + '/svg/logo-color.svg';

  constructor(@Optional() @Inject(RESPONSE) private response: any, private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object, private el: ElementRef, private render: Renderer2) {
    // Handle theme depending on path
    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('espace-recruteurs')) {
          this.isRecruiters = true;

        } else {
          this.isRecruiters = false;
        }
      }
    });
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.statusCode = 404;
    }

    this.render.addClass(this.el.nativeElement.parentElement.parentElement, 'notfound-page');

    if (this.env.production) {
      /*setTimeout(() => {
        this.isRecruiters ? this.router.navigate(['espace-recruteurs']) : this.router.navigate(['/']);
      }, 3500);*/

      // if (this.env.GlobalVars.name === 'jobtransport') {
      //   (<any>window).location.href = `https://www.${this.env.GlobalVars.name}.com/404.html`;
      // } else {
      //   (<any>window).location.href = `https://www.${this.env.GlobalVars.name}.fr/404.html`;
      // }
    }
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
    this.render.removeClass(this.el.nativeElement.parentElement.parentElement, 'notfound-page');

  }

}
