import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { PermissionsService } from '../services/permissions.service';


@Injectable({
  providedIn: 'root'
})
export class PermissionsInterceptor implements HttpInterceptor {
  constructor(private permissionsService: PermissionsService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req?.url?.includes(environment.path.api) && req?.headers?.has('x-jobology-pre-auth') || req?.url?.includes(environment.path.api2) && req?.headers?.has('x-jobology-pre-auth')) {
      return Observable.create(observer => {

        next.handle(req).subscribe(
          event => {
            //console.log('REQ', req)
            if (event instanceof HttpResponse) {

              // TALEND API
              if (req?.url?.includes(environment.path.api2)) {

              }

              // Get only permissions with correct header 
              // if (req?.headers.has('x-jobology-pre-auth') && req?.body) {
              if (req?.headers?.has('x-jobology-pre-auth')) {

                if (req?.body) {
                  let name = req.body?.operationName ? req.body?.operationName : req?.url?.split('permission=')[1];
                  // Handle API REST for ORGANISATION (societe, utilisateur, tenants) / SOURCING
                  if (req.url.includes('organisation') || req.url.includes('sourcing')) {
                    if (Array.isArray(req.body)) {
                      name = Object.keys(req.body[0]).toString();
                    } else {
                      name = Object.keys(req.body).toString();
                    }
                  }

                  this.permissionsService.getPermissionsResponse$.next({ name: name, permission: true, status: event.status })

                } else {
                  let name = req?.url?.split('permission=')[1];

                  this.permissionsService.getPermissionsResponse$.next({ name: name, permission: true, status: event.status })
                }


              }

              //console.log("NEXT", req.body.operationName)
              observer.next(event);

            }
          },
          err => {
            //  Permissions with correct header and Forbidden permission
            //  if (req.headers.has('x-jobology-pre-auth') && err.status === 403) {
            if (err?.status === 403) {

              if (req?.headers?.has('x-jobology-pre-auth')) {
                if (req?.body) {
                  let name = req?.body?.operationName

                  // Handle API REST for ORGANISATION (societe, utilisateur, tenants) / SOURCING
                  if (req?.url.includes('organisation') || req?.url.includes('sourcing')) {
                    name = Object.keys(req.body).toString();
                  }

                  this.permissionsService.getPermissionsResponse$.next({ name: req?.body?.operationName, permission: false, preAuth: true, status: err.status })
                } else {
                  let name = req?.url.split('permission=')[1];

                  this.permissionsService.getPermissionsResponse$.next({ name: name, permission: false, preAuth: true, status: err.status })


                }
              } else {
                this.permissionsService.getPermissionsResponse$.next({ name: req?.body?.operationName, permission: false, status: err.status })
              }

            }

            observer.error(err);

          },
          () => {
            observer.complete();
          }
        )
      });
    }
    return next.handle(req);
  }
}